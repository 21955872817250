/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from '@chakra-ui/react'
import Select from '../chakra-react-select'
import React, { useState, useEffect } from 'react'
import { useLoadActivityListV1 } from '../../hooks/activity.hooks'
import { useAddParentActivity } from '../../hooks/activity.hooks'
import { useLanguageContext } from '../../context/locale.context'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../../containers/repository/repo.utils'

const SelectParentAcitivity = ({ isOpen, onClose, activity }) => {
  const [isError, setisError] = useState(false)
  const [orderFlag, setOrderFlag] = useState(null)
  const { mutate, isLoading } = useLoadActivityListV1()
  const [options, setOptions] = useState([])
  const { mutate: mutateAddparentActivity } = useAddParentActivity()
  const [selectedValue, setSelectedValue] = useState(0)
  const {
    state: { locale },
  } = useLanguageContext()
  useEffect(() => {
    mutate(
      {
        flag: 0,
        is_active: 0,
        is_due: 0,
        is_search: 0,
        is_unread: 0,
        limit_value: 30,
        page_limit: 30,
        page_start: 0,
        queue_id: 0,
        search_string: '',
        sort_flag: 4,
        start_from: 0,
        status_type_id: 0,
      },
      {
        onSuccess: res => {
          let data = res.list.map(
            ({
              activity_cuid_1,
              activity_cuid_2,
              activity_cuid_3,
              activity_id,
              activity_title,
              operating_asset_first_name,
              activity_type_category_id,
              activity_type_id,
            }) => ({
              details: {
                activity_cuid_1,
                activity_cuid_2,
                activity_cuid_3,
                operating_asset_first_name,
                activity_id,
                activity_type_category_id,
                activity_type_id,
              },
              label: activity_title,
              value: `${
                activity_id +
                '|' +
                activity_title +
                '|' +
                activity_type_category_id
              }`,
            })
          )

          setOptions([...data])
        },
        onError: async error => {
          setisError(true)
        },
      }
    )
  }, [])

  const setParentAcitivity = () => {
    if (!!orderFlag) {
      mutateAddparentActivity(
        {
          activity_id: activity.activity_id,
          parent_activity_id: orderFlag.details.activity_id,
        },
        {
          onSuccess: () => {
            setOrderFlag(null)
          },
        }
      )
    }
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="sm">
        <ModalHeader fontSize="lg" fontWeight="400">
          {locale['Set Parent Activity']}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          {!isLoading && (
            <FormControl id="selectType">
              <FormLabel>{locale['Select Activity']}</FormLabel>
              <Select
                size="sm"
                placeholder={locale['Select...']}
                options={options
                  .filter(
                    item =>
                      item.details.activity_type_category_id ===
                        activity.activity_type_category_id &&
                      item.label !== activity.activity_title
                  )
                  .filter(Boolean)}
                value={orderFlag}
                onChange={e => {
                  setOrderFlag(e)
                }}
              />
            </FormControl>
          )}
          {isLoading && (
            <Progress colorScheme={localStorage.getItem('color')} my={5} size="md" isIndeterminate />
          )}
          {!orderFlag && (
            <Text color="red">
              {locale['Please select the parent activity!']}
            </Text>
          )}
          {isError && (
            <Text color="red">
              {locale['Something went wrong please try again!']}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            borderRadius="sm"
            size="sm"
            variant="outline"
            colorScheme={localStorage.getItem('color')}
            mr={3}
            onClick={() => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DIALOG_BOX,
                action: 'Select Parent Activity',
                label: 'Close Modal',
              })
              onClose()
            }}
          >
            {locale['Close']}
          </Button>
          {!isError && (
            <Button
              borderRadius="sm"
              size="sm"
              variant="solid"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              isLoading={isLoading}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Select Parent Activity',
                  label: 'Set Parent Activity',
                })
                setParentAcitivity()
              }}
            >
              {locale['Set']}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default SelectParentAcitivity
