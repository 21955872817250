/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	HStack,
	VStack,
	Box,
	Input,
	Textarea,
	FormControl,
	FormLabel,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	DrawerHeader,
	Divider,
	Button,
	Text,
	DrawerFooter,
	useDisclosure,
} from '@chakra-ui/react'
import Select from '../chakra-react-select'
import { sortBy, unionBy } from 'lodash'
import React, { useCallback, useState, useEffect } from 'react'
import { useLoadAllFormList, useSendEmail } from '../../hooks/form.hooks'
import {
	useAddTimelineUpdate,
	useLoadActivityReceiverEmailId,
	useLoadActivityTypeList,
} from '../../hooks/activity.hooks'
import { useLoadAssetInlineCollection } from '../../hooks/asset.hooks'
import {
	getUrlId,
	getS3BucketName,
	getLocalAuthDetails,
	validateEmailUsernamePassword,
} from '../../utils/common.util'
import { useAuthContext } from '../../context/auth.context'
import { customEmailTemplate } from '../../utils/email-templates.utils'
import { AlertBox } from './../AlertBox/index'
import { useSharedContext } from './../../context/shared.context'
import { useLanguageContext } from './../../context/locale.context'
import { sharedConst } from '../../utils/action.constant'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_DIALOG_BOX,
// 	sectionDetailedTrack,
// } from './../../containers/repository/repo.utils'
import { useMsal } from '@azure/msal-react'
import { useIsAuthenticated } from '@azure/msal-react'
import { sendEmailAttachmentsViaGraph } from '../../graph'
import { loginRequest } from '../../authConfig'
import Card from '../card'
import { predictAgeAndGender } from 'face-api.js'

const SendEmail = ({ isOpen, onClose, activity }) => {
	const { mutate: mutateTimeline } = useAddTimelineUpdate()
	const { mutate: mutateActivityReceiverEmailId } =
		useLoadActivityReceiverEmailId()
	const { mutate: mutateLoadAllForms, isLoading } = useLoadAllFormList()
	const { mutate: mutateActivityTypes } = useLoadActivityTypeList()
	const { mutate: mutateSendEmail } = useSendEmail()
	const { mutate: loadInlineData } = useLoadAssetInlineCollection()
	const { isOpen: isAlertOpen, onOpen, onClose: alertClose } = useDisclosure()
	const { instance, accounts } = useMsal()
	const isAuthenticated = useIsAuthenticated()
	const [mappedForms, setMappedForms] = useState([])
	const [email, setEmail] = useState('')
	const [receiverName, setReceiverName] = useState('')
	const [senderName, setSenderName] = useState('')
	const [selectedUpdateForms, setUpdateForms] = useState([])
	const [selectedReviewForms, setReviewForms] = useState({})
	const [subject, setSubject] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [activityType, setActivityType] = useState({})
	const [username, setUsername] = useState('')
	const [alertMessage, setAlertMessage] = useState('')
	const [fileAttachments, setFileAttachments] = useState([])

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const { dispatch } = useSharedContext()

	const {
		activity_id,
		activity_type_id,
		parent_status_id,
		activity_status_id,
		activity_type_category_id,
		activity_type_flag_enable_email_prefill,
		activity_type_email_prefill_field_id,
	} = activity
	const loginmode = sessionStorage.getItem('__gr__l_login_mode_otp')

	const loadAllForms = () => {
		// const {
		//   activity_id,
		//   activity_type_id,
		//   parent_status_id,
		//   activity_status_id,
		//   activity_type_category_id,
		// } = activity;
		mutateLoadAllForms(
			{
				statusBasedForms: {
					activity_status_id: parent_status_id || activity_status_id,
					workflow_activity_id: activity.activity_id,
					activity_type_category_id,
					flag: 0,
				},
				submittedForms: {
					flag: 1,
					form_id: 0, // form id should be 0 to load all forms
					activity_id,
					activity_type_id,
					activity_type_category_id,
				},
			},
			{
				onSuccess: async data => {
					data = sortBy(data, ['isOrder']).reverse()
					setMappedForms(data)
				},
				onError: async err => {
					console.log('Error goes here', err)
				},
			}
		)
		mutateActivityTypes(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityType(data)
				},
			}
		)
	}

	const sendEmailv8 = payload => {
		mutateSendEmail(
			{
				payload,
			},
			{
				onSuccess: async data => {
					if (data?.status) {
						setAlertMessage('E-mail sent successfully!')
						onOpen()
						resetForm()
						// onClose();
					}
				},
			}
		)
	}

	const createFormReviewUrl = async (email, form) => {
		const formConfigRoms = JSON.parse(form?.data?.form_config_roms)
		const digitalFormIds = []
		if (formConfigRoms) {
			for (const key in formConfigRoms) {
				const digitalFormId = formConfigRoms[key].digital_form_id
				digitalFormIds.push(digitalFormId)
			}
		}

		const JsonStrReviewCAF = {
			account_id: authData.account_id,
			activity_id: activity.activity_id,
			asset_id: authData.asset_id,
			auth_asset_id: authData.asset_id,
			auth_token: authData.asset_token_auth,
			asset_token_auth: authData.asset_token_auth,
			disabledProperty: true,
			form_id: form.value,
			form_name: form.label,
			form_transaction_id: form.data.form_transaction_id,
			org_id: authData.organization_id,
			workforce_id: authData.workforce_id,
			organization_id: authData.organization_id,
			s3BucketName: getS3BucketName(),
			form_flag_prefill_enabled: form.form_flag_prefill_enabled,
			activity_type_id: activity.activity_type_id,
			parent_status_id: activity?.parent_status_id,
			activity_status_id: activity?.activity_status_id,
			activity_type_category_id: activity?.activity_type_category_id,
			digital_form_id: digitalFormIds.length > 0 ? digitalFormIds[0] : '',
		}
		let data = await getUrlId(email, JsonStrReviewCAF)
		return `${process.env.REACT_APP_EXT_URL}forms/preview/${btoa(
			JSON.stringify(data)
		)}`
	}

	const createFormUpdateUrlParam = () => {
		return {
			organization_id: authData.organization_id,
			account_id: authData.account_id,
			workforce_id: authData.workforce_id,
			asset_id: authData.asset_id,
			auth_asset_id: authData.asset_id,
			asset_token_auth: authData.asset_token_auth, //"05986bb0-e364-11e8-a1c0-0b6831833754",
			activity_id: activity.activity_id,
			activity_type_category_id: 9,
			activity_stream_type_id: 705,
			form_id: 0,
			form_flag_prefill_enabled: 0,
			activity_type_id: activity.activity_type_id,
			activity_type_id_form: activityType.activity_type_id,
			type: 'approval',
			asset_first_name: authData.asset_first_name,
			asset_phone_number: authData.asset_phone_number,
			operating_asset_first_name: authData.operating_asset_first_name,
			s3BucketName: getS3BucketName(),
		}
	}

	const getFormsToUpdate = async (updatePayload, toUpdate, email) => {
		const forms = toUpdate.map(async item => {
			updatePayload.form_id = item.value
			updatePayload.form_flag_prefill_enabled =
				item?.data?.form_flag_prefill_enabled
			let data = await getUrlId(email, updatePayload)
			let url = process.env.REACT_APP_EXT_URL
			return {
				label: item.label,
				value: `${url}forms/entry/${btoa(JSON.stringify(data))}`,
			}
		})
		return await Promise.all([...forms])
	}

	const sendMailParams = async () => {
		let emails
		if (email) {
			emails = email.split(',')
		}
		const reviewUrl = await createFormReviewUrl(emails, selectedReviewForms)
		const updatePayload = createFormUpdateUrlParam()
		const formsForUpdate = await getFormsToUpdate(
			updatePayload,
			selectedUpdateForms,
			emails
		)

		return {
			asset_id: authData.asset_id,
			organization_id: authData.organization_id,
			asset_token_auth: authData.asset_token_auth,
			customer_name: receiverName,
			email_subject: subject,
			email_body: message,
			email_receivers: emails,
			email_sender_name: senderName,
			outlook_email_integration:
				authData.organization_flag_email_integration_enabled, //=== 1 ? 1 : 0,
			email_sender: authData.operating_asset_email_id,
			email_sender_password: authData.asset_email_password,
			flag: 0,
			email_sender_username: username,
			email_sender_asset_id: authData.asset_id,
			html_template: btoa(
				customEmailTemplate({
					org_image: authData.organization_image_path || null,
					org_id: authData.organization_id,
					org_name: authData.organization_name,
					senderName: senderName,
					recieverName: receiverName,
					formsForReview: selectedReviewForms,
					reviewUrl: reviewUrl,
					message: message,
					formsForUpdate,
				})
			),
		}
	}

	const sendMailViaGraph = useCallback(
		mailParams => {
			instance
				.acquireTokenSilent({
					...loginRequest,
					account: accounts[0],
				})
				.then(response => {
					if (
						!!sendEmailAttachmentsViaGraph(response.accessToken, mailParams)
					) {
						setAlertMessage('E-mail sent successfully!')
						onOpen()
						resetForm()
					}
				})
		},
		[instance, accounts]
	)

	const onSendMail = async () => {
		const { operating_asset_first_name } = authData
		if (
			!email ||
			!senderName ||
			!receiverName ||
			// !selectedReviewForms ||
			// !selectedUpdateForms ||
			!subject ||
			!message ||
			!Object.keys(selectedReviewForms).length
		) {
			setError('All Fields are mandatory!')
		} else {
			setError('')
			const payload = await sendMailParams()
			if (validateSendMail(payload)) {
				if (loginmode !== '3') {
					sendEmailv8(payload)
				}
				if (isAuthenticated && loginmode === '3') {
					let emails
					if (email) {
						emails = email.split(',')
					}
					sendMailViaGraph({
						to: email,
						subject: subject,
						//message: message,
						message: customEmailTemplate({
							org_image: authData.organization_image_path || null,
							org_id: authData.organization_id,
							org_name: authData.organization_name,
							senderName: senderName,
							recieverName: receiverName,
							formsForReview: selectedReviewForms,
							reviewUrl: await createFormReviewUrl(emails, selectedReviewForms),
							message: message,
							formsForUpdate: await getFormsToUpdate(
								createFormUpdateUrlParam(),
								selectedUpdateForms,
								emails
							),
						}),
						attachments: fileAttachments,
					})
				}
				let payloadTimeline = {
					reminder_email: {
						sender_email: payload.email_sender,
						sender_name: payload.email_sender_name,
						receiver_email: payload.email_receiver,
						receiver_name: payload.customer_name,
						subject: payload.email_subject,
						body: payload.email_body,
						form_trigger: {
							[selectedReviewForms.value]: {
								name: selectedReviewForms.label,
							},
						},
						form_fill: selectedUpdateForms.map(item => {
							return { value: item?.label, id: item?.value }
						}),
						form_approval: [
							{
								value: selectedReviewForms?.label,
								id: selectedReviewForms?.value,
							},
						],
					},
				}
				mutateTimeline(
					{
						activity_stream_type_id: 715,
						timeline_stream_type_id: 715,
						operating_asset_first_name,
						activity_id,
						activity_type_category_id,
						activity_type_id,
						// data_entity_inline: JSON.stringify(payloadTimeline),
						activity_timeline_collection: JSON.stringify(payloadTimeline),
					},
					{
						onSuccess: async data => {
							dispatch({
								type: sharedConst.REFRESH_TIMELINE,
							})
						},
					}
				)
			}
		}
	}

	const validateSendMail = payload => {
		if (
			payload.outlook_email_integration === 1 ||
			payload.outlook_email_integration === 3
		) {
			const { valid, msg } = validateEmailUsernamePassword(authData)
			if (valid) return true
			else {
				setAlertMessage(msg)
				onOpen()
				return false
			}
		} else if (payload.outlook_email_integration === 0) {
			return true
		}
		return false
	}

	const resetForm = () => {
		// setMappedForms([]);
		setEmail('')
		setReceiverName('')
		setSenderName(authData.operating_asset_first_name || '')
		setUpdateForms([])
		setReviewForms({})
		setSubject('')
		setMessage('')
	}

	const customClose = () => {
		alertClose()
		onClose()
		// loadAllForms();
	}

	useEffect(() => {
		if (!!activity) {
			loadAllForms()
			resetForm()
			if (
				!!activity_type_flag_enable_email_prefill &&
				activity_type_flag_enable_email_prefill === 1
			) {
				//useLoadActivitySendEmailId()
				if (!!activity_type_email_prefill_field_id) {
					const parseData = JSON.parse(
						activity_type_email_prefill_field_id
					).prefill_fields
					mutateActivityReceiverEmailId(
						{
							activity_id: activity_id,
							form_id: parseData.form_id,
							workflow_activity_id: activity_id,
							organization_id: activity.organization_id,
							account_id: activity.account_id,
							asset_id: authData.asset_id,
							auth_asset_id: authData.asset_id,
							asset_type_id: authData.asset_type_id,
							asset_token_auth: authData.asset_token_auth,
							field_id: parseData.field_id,
						},
						{
							onSuccess: async res => {
								if (!!res) {
									setEmail(res)
								}
							},
							onError: async err => {
								console.log(err, 'err')
							},
						}
					)
				}
			}
		}
	}, [activity])

	const loadUserDetails = useCallback(data => {
		const payload = getLocalAuthDetails()
		loadInlineData(
			{ ...payload },
			{
				onSuccess: async res => {
					let userData = res.data.response.data
					localStorage.setItem(
						'ORGANIZATION_ONHOLD',
						userData && userData.organization_flag_dashboard_onhold
					)
					if (
						!!userData?.operating_asset_username &&
						!!userData?.asset_email_password
					) {
						setUsername(userData?.operating_asset_username)
					} else {
						setUsername('')
					}
				},
				onError: async err => {
					console.log(err, 'err')
				},
			}
		)
	})

	useEffect(() => {
		setSenderName(authData.operating_asset_first_name)
		loadUserDetails()
	}, [])
	useEffect(() => {
		setSubject('')
		setMessage('')
		selectedUpdateForms?.map((ele, i) => {
			let mailText = ele?.data?.form_static_subject_text
			if (mailText) {
				const parsedObject = JSON.parse(mailText)
				const mailBody = parsedObject.static_subject_text.mail_body
				const mailSubject = parsedObject.static_subject_text.mail_subject
				setSubject(mailSubject)
				setMessage(mailBody)
			}
		})
	}, [selectedUpdateForms])
	const reviewForms =
		Array.isArray(mappedForms) &&
		mappedForms
			.map(form => {
				if (!!form.isSubmitted) {
					return {
						label: form.form_name,
						value: form.form_id,
						data: form,
					}
				}
			})
			.filter(Boolean)

	const fileFields = reviewForms
		.map(r => {
			try {
				return {
					form_id: r.value,
					files: JSON.parse(r.data?.data_entity_inline)?.form_submitted.filter(
						f => [11, 13].includes(f.field_data_type_category_id)
					),
				}
			} catch (e) {
				return null
			}
		})
		.filter(Boolean)

	const fileOptions = fileFields
		.filter(f => f.form_id === selectedReviewForms?.value)
		.reduce((a, m) => {
			return m.files.map(f => {
				return {
					label: f.field_name,
					value: f.field_value,
				}
			})
		}, [])
	const updateForms =
		Array.isArray(mappedForms) &&
		mappedForms
			.map(form => {
				if (!form.isSubmitted && form.form_submission_type_id !== 2) {
					return {
						label: form.form_name,
						value: form.form_id,
						data: form,
					}
				}
			})
			.filter(Boolean)

	const handleLogin = loginType => {
		if (loginType === 'popup') {
			instance.loginPopup(loginRequest).catch(e => {
				console.log(e)
			})
		} else if (loginType === 'redirect') {
			instance.loginRedirect(loginRequest).catch(e => {
				console.log(e)
			})
		}
	}

	const handleLogout = logoutType => {
		if (logoutType === 'popup') {
			instance.logoutPopup({
				postLogoutRedirectUri: '/',
				mainWindowRedirectUri: '/',
			})
		} else if (logoutType === 'redirect') {
			instance.logoutRedirect({
				postLogoutRedirectUri: '/',
			})
		}
	}

	return (
		<Drawer
			className={`form-add-onfly-drawer`}
			isOpen={isOpen}
			placement='right'
			onClose={onClose}
			size='md'
		>
			<DrawerOverlay className={`form-add-onfly-drawer-overlay`} />
			<DrawerContent className={`form-add-onfly-drawer-content`}>
				<DrawerHeader fontSize='lg' fontWeight='900'>
					{locale['Compose E-Mail']}
				</DrawerHeader>
				<Divider />
				<DrawerCloseButton className={`form-add-onfly-drawer-close-button`} />
				<DrawerBody className={`form-add-onfly-drawer-body`}>
					{loginmode === '3' && (
						<Card>
							<HStack justifyContent={'space-between'}>
								<Text style={{ fontWeight: 'bold' }}>
									{' '}
									Microsoft Identity Platform
								</Text>
								{isAuthenticated ? (
									<Button
										borderRadius='sm'
										size='sm'
										variant='solid'
										colorScheme='red'
										onClick={() => {
											handleLogout('popup')
										}}
									>
										Sign Out
									</Button>
								) : (
									<Button
										borderRadius='sm'
										size='sm'
										variant='solid'
										colorScheme='green'
										onClick={() => {
											handleLogin('popup')
										}}
									>
										Sign In
									</Button>
								)}
							</HStack>
						</Card>
					)}
					<VStack my={5}>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Receiver email']}
							</FormLabel>
							<Input
								type='email'
								placeholder={locale['Enter email']}
								value={email}
								onChange={e => setEmail(e.target.value)}
							/>
							<Text color='gray'>
								{locale['Commas can be used to separate multiple recipients']}{' '}
							</Text>
						</FormControl>
						<FormControl>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Receiver Name']}
							</FormLabel>
							<Input
								placeholder={locale['Enter Receiver Name']}
								value={receiverName}
								onChange={e => setReceiverName(e.target.value)}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Sender Name']}
							</FormLabel>
							<Input
								placeholder={locale['Enter Sender Name']}
								value={senderName}
								onChange={e => setSenderName(e.target.value)}
							/>
						</FormControl>
						<FormControl>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Select & Attach Form for Update']}
							</FormLabel>
							<Select
								isMulti={true}
								id={'forms-for-update'}
								options={updateForms}
								onChange={setUpdateForms}
								placeholder={locale['Select Forms']}
								borderRadius='md'
								value={selectedUpdateForms}
								size='md'
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Select & Attach Form for Review']}
							</FormLabel>
							<Select
								id={'forms-for-review'}
								options={reviewForms}
								onChange={setReviewForms}
								placeholder={locale['Select Forms']}
								borderRadius='md'
								value={selectedReviewForms}
								size='md'
							/>
						</FormControl>
						{isAuthenticated && (
							<FormControl isRequired>
								<FormLabel fontWeight='400' fontSize='md' my={1}>
									{['Select files for attachment']}
								</FormLabel>
								<Select
									isMulti={true}
									id={'forms-for-attachment'}
									options={fileOptions}
									onChange={setFileAttachments}
									placeholder={['Select Attachments']}
									borderRadius='md'
									value={fileAttachments}
									size='md'
								/>
							</FormControl>
						)}
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Add Subject Line']}
							</FormLabel>
							<Input
								placeholder={locale['Add Subject Line']}
								value={subject}
								onChange={e => setSubject(e.target.value)}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Message']}
							</FormLabel>
							<Textarea
								placeholder={locale['Type in a message']}
								value={message}
								onChange={e => setMessage(e.target.value)}
							/>
						</FormControl>
					</VStack>
					<Text color='red'>{error}</Text>
				</DrawerBody>
				<DrawerFooter>
					<Box mt={2} w='full'>
						<AlertBox
							isOpen={isAlertOpen}
							onClose={customClose}
							Message={alertMessage}
						/>
						<HStack justifyContent='flex-end'>
							<Button
								variant='outline'
								size='md'
								borderRadius='sm'
								colorScheme={localStorage.getItem('color')}
								bg='white.400'
								w='140px'
								ml={1}
								// onClick={onClose}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_DIALOG_BOX,
									// 	action: 'Send Email',
									// 	label: 'Reset Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Compose EMail',
									// 	buttonName: 'Reset',
									// })
									resetForm()
								}}
							>
								{locale['Reset']}
							</Button>
							<Button
								variant='solid'
								size='md'
								borderRadius='sm'
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								w='140px'
								ml={1}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_DIALOG_BOX,
									// 	action: 'Send Email',
									// 	label: `Sending Mail From ${senderName}`,
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Compose EMail',
									// 	buttonName: 'Send Email',
									// })
									onSendMail()
								}}
							>
								{locale['Send Email']}
							</Button>
						</HStack>
					</Box>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	)
}

export default SendEmail
