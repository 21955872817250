import React from 'react'

import { HStack, Text, Select, IconButton, Icon } from '@chakra-ui/react'

import { IoMdArrowForward, IoMdArrowBack } from 'react-icons/io'
import { sectionDetailedTrack } from '../../containers/repository/repo.utils'
import { GA_CATEGORY_PAGINATION } from './../../containers/repository/repo.utils'

export const Pagination = ({ limit, page, setlimit, setpage, total }) => {
	return (
		<HStack w='100%' flex={1} justifyContent='flex-end'>
			<Text size='sm'>Rows per page</Text>
			<Select
				variant='outline'
				size='sm'
				maxW='70px'
				bg='white'
				value={limit}
				onChange={e => {
					setlimit(parseInt(e.target.value))
				}}
			>
				<option value={10}>10</option>
				<option value={20}>20</option>
				<option value={30}>30</option>
			</Select>
			<Text size='sm'>
				{(page - 1) * limit * 1} - {limit * page > total ? total : limit * page}{' '}
				of {total}
			</Text>
			<IconButton
				size='sm'
				aria-label='previous'
				bg='white'
				variant='outline'
				icon={<Icon as={IoMdArrowBack} w={4} h={4} />}
				onClick={() => {
					sectionDetailedTrack({
						category: GA_CATEGORY_PAGINATION,
						action: 'Go Back',
					})
					setpage(page - 1)
				}}
				isDisabled={!(page - 1)}
			></IconButton>
			<IconButton
				size='sm'
				aria-label='previous'
				bg='white'
				variant='outline'
				isDisabled={total <= limit * page}
				onClick={() => {
					sectionDetailedTrack({
						category: GA_CATEGORY_PAGINATION,
						action: 'Go Ahead',
					})
					setpage(page + 1)
				}}
				icon={<Icon as={IoMdArrowForward} w={4} h={4} />}
			></IconButton>
		</HStack>
	)
}
