/* eslint-disable react-hooks/exhaustive-deps */
import {
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Divider,
	List,
	ListIcon,
	ListItem,
	Text,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef } from 'react'
import { BsCircle } from 'react-icons/bs'
import { MdArrowDropDown, MdCheckCircle } from 'react-icons/md'
import { sectionDetailedTrack } from '../../containers/repository/repo.utils'
import { useLoadQueueList } from '../../hooks/shared.hooks'
import { GA_CATEGORY_FILES } from './../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const QueueType = ({
	selectedQueue,
	onFilterChange,
	onQueueSelection,
	selectedIndex,
}) => {
	const { mutate, isError, data } = useLoadQueueList()
	const {
		state: { locale },
	} = useLanguageContext()

	const loadQueueList = useCallback(() => {
		mutate({})
	}, [])

	useEffect(() => {
		loadQueueList()
	}, [])

	if (!data || !data.length || !!isError) {
		return null
	}

	return (
		<AccordionItem border='none' boxShadow='md' borderRadius='md' mb={2}>
			<AccordionButton
				_expanded={{
					bg: 'secondary',
					color: 'brand.900',
					borderRadius: 'md',
				}}
				_focus={{
					border: 'none',
				}}
			>
				<Box flex='1' textAlign='left'>
					<Text size='sm' fontWeight='500'>
						{locale['Select Queue']}
					</Text>
				</Box>
				<AccordionIcon as={MdArrowDropDown} />
			</AccordionButton>
			<AccordionPanel
				pb={4}
				minH='200px'
				maxH='calc(100vh - 555px)'
				overflowY='auto'
			>
				<List spacing={3}>
					{(data || []).map((queue, i) => {
						return (
							<div key={`${queue.queue_id}-${i}`}>
								<ListItem
									cursor='pointer'
									py={1}
									onClick={() => {
										sectionDetailedTrack({
											category: GA_CATEGORY_FILES,
											action: 'Queue Selection',
										})
										onQueueSelection(queue)
										onFilterChange({})
									}}
									display='flex'
								>
									<ListIcon
										as={
											!selectedQueue.isStatic &&
											selectedQueue.queue_id === queue.queue_id
												? MdCheckCircle
												: BsCircle
										}
										color={localStorage.getItem('color')}
										w={6}
										h={6}
									/>
									<Text fontSize='sm'>{queue.queue_name}</Text>
								</ListItem>
								<Divider mt={1} />
							</div>
						)
					})}
				</List>
			</AccordionPanel>
		</AccordionItem>
	)
}

export default QueueType
