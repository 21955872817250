/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	HStack,
	VStack,
	Box,
	Input,
	Textarea,
	FormControl,
	FormLabel,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	DrawerHeader,
	Divider,
	Button,
	Text,
	DrawerFooter,
	useDisclosure,
} from '@chakra-ui/react'
import Select from '../chakra-react-select'
import { sortBy, unionBy } from 'lodash'
import React, { useCallback, useState, useEffect } from 'react'
import { useLoadAllFormList, useSendEmail } from '../../hooks/form.hooks'
import {
	useAddTimelineUpdate,
	useLoadActivityTypeList,
} from '../../hooks/activity.hooks'
import { useLoadAssetInlineCollection } from '../../hooks/asset.hooks'
import {
	getUrlId,
	getShortUrlId,
	getS3BucketName,
	getLocalAuthDetails,
	validateEmailUsernamePassword,
} from '../../utils/common.util'
import { useAuthContext } from '../../context/auth.context'
import { customEmailTemplate } from '../../utils/email-templates.utils'
import { AlertBox } from './../AlertBox/index'
import { useLanguageContext } from './../../context/locale.context'
import { sharedConst } from '../../utils/action.constant'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_DIALOG_BOX,
// 	sectionDetailedTrack,
// } from './../../containers/repository/repo.utils'
import {
	getAddHoursToCurrentDateUTC,
	formatDateTime,
} from '../../utils/date.utils'

const OriginFormSendEmail = ({ isOpen, onClose, activity, form }) => {
	console.log('form', form)
	const { mutate: mutateSendEmail } = useSendEmail()
	const { mutate: loadInlineData } = useLoadAssetInlineCollection()
	const { isOpen: isAlertOpen, onOpen, onClose: alertClose } = useDisclosure()

	const [mappedForms, setMappedForms] = useState([form])
	const [email, setEmail] = useState('')
	const [receiverName, setReceiverName] = useState('')
	const [senderName, setSenderName] = useState('')
	const [selectedUpdateForms, setUpdateForms] = useState([])
	const [selectedReviewForms, setReviewForms] = useState({})
	const [subject, setSubject] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [activityType, setActivityType] = useState({})
	const [username, setUsername] = useState('')
	const [alertMessage, setAlertMessage] = useState('')

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const sendEmailv8 = payload => {
		mutateSendEmail(
			{
				payload,
			},
			{
				onSuccess: async data => {
					if (data.status) {
						setAlertMessage('E-mail sent successfully!')
						onOpen()
						resetForm()
						onClose()
					}
				},
			}
		)
	}

	const createFormUpdateUrlParam = selectedReviewForms => {
		return {
			organization_id: authData.organization_id,
			account_id: authData.account_id,
			workforce_id: authData.workforce_id,
			asset_id: authData.asset_id,
			auth_asset_id: authData.asset_id,
			asset_token_auth: authData.asset_token_auth, //"05986bb0-e364-11e8-a1c0-0b6831833754",
			activity_id: activity.activity_id,
			activity_type_category_id: 9,
			activity_stream_type_id: 705,
			form_id: 0,
			form_flag_prefill_enabled: 0,
			activity_type_id: activity.activity_type_id,
			activity_type_id_form: activityType.activity_type_id,
			type: 'approval',
			asset_first_name: authData.asset_first_name,
			asset_phone_number: authData.asset_phone_number,
			operating_asset_first_name: authData.operating_asset_first_name,
			s3BucketName: getS3BucketName(),
			from_date: formatDateTime(),
			expiry_date: getAddHoursToCurrentDateUTC(
				new Date(),
				selectedReviewForms.data.activity_type_web_url_duration
			),
			activity_type_welcome_message: form.activity_type_welcome_message,
			activity_type_closing_message: form.activity_type_closing_message,
			form_workflow_activity_type_id: form.form_workflow_activity_type_id,
		}
	}

	const getFormsToUpdate = async (updatePayload, toUpdate, email) => {
		let forms = {}
		updatePayload.form_id = toUpdate.value
		updatePayload.form_flag_prefill_enabled =
			toUpdate?.data?.form_flag_prefill_enabled
		let data = await getUrlId(email, updatePayload)
		let url = process.env.REACT_APP_EXT_URL
		forms['label'] = toUpdate.label
		forms['value'] = `${url}weblink/${btoa(JSON.stringify(data))}`
		return await Promise.all([forms])
	}

	const sendMailParams = async () => {
		let emails
		if (email) {
			emails = email.split(',')
		}
		const updatePayload = createFormUpdateUrlParam(selectedReviewForms)
		const formsForUpdate = await getFormsToUpdate(
			updatePayload,
			selectedReviewForms,
			emails
		)
		return {
			asset_id: authData.asset_id,
			organization_id: authData.organization_id,
			asset_token_auth: authData.asset_token_auth,
			customer_name: receiverName,
			email_subject: subject,
			email_body: message,
			email_receivers: emails,
			email_sender_name: senderName,
			outlook_email_integration:
				authData.organization_flag_email_integration_enabled === 1 ? 1 : 0,
			email_sender: authData.asset_email_id,
			flag: 0,
			email_sender_username: username,
			email_sender_asset_id: authData.asset_id,
			activity_type_web_url_duration:
				selectedReviewForms.data.activity_type_web_url_duration,
			from_date: formatDateTime(),
			expiry_date: getAddHoursToCurrentDateUTC(
				new Date(),
				selectedReviewForms.data.activity_type_web_url_duration
			),
			html_template: btoa(
				customEmailTemplate({
					org_image: authData.organization_image_path || null,
					org_id: authData.organization_id,
					org_name: authData.organization_name,
					senderName: senderName,
					recieverName: receiverName,
					formsForReview: selectedReviewForms,
					reviewUrl: null,
					message: message,
					formsForUpdate,
				})
			),
		}
	}

	const onSendMail = async () => {
		if (
			!email ||
			!senderName ||
			!receiverName ||
			!subject ||
			!message ||
			!Object.keys(selectedReviewForms).length
		) {
			setError('All Fields are mandatory!')
		} else {
			setError('')
			const payload = await sendMailParams()
			if (validateSendMail(payload)) {
				sendEmailv8(payload)
			}
		}
	}

	const validateSendMail = payload => {
		if (payload.outlook_email_integration === 1) {
			const { valid, msg } = validateEmailUsernamePassword(authData)
			if (valid) return true
			else {
				setAlertMessage(msg)
				onOpen()
				return false
			}
		} else if (payload.outlook_email_integration === 0) {
			return true
		}
		return false
	}

	const resetForm = () => {
		setEmail('')
		setReceiverName('')
		setSenderName(authData.operating_asset_first_name || '')
		setUpdateForms([])
		setReviewForms({})
		setSubject('')
		setMessage('')
	}

	const customClose = () => {
		alertClose()
		onClose()
		// loadAllForms();
	}

	const loadUserDetails = useCallback(data => {
		const payload = getLocalAuthDetails()
		loadInlineData(
			{ ...payload },
			{
				onSuccess: async res => {
					let userData = res.data.response.data
					localStorage.setItem(
						'ORGANIZATION_ONHOLD',
						userData && userData.organization_flag_dashboard_onhold
					)
					if (
						!!userData?.operating_asset_username &&
						!!userData?.asset_email_password
					) {
						setUsername(userData?.operating_asset_username)
					} else {
						setUsername('')
					}
				},
				onError: async err => {
					console.log(err, 'err')
				},
			}
		)
	})

	useEffect(() => {
		setSenderName(authData.operating_asset_first_name)
		loadUserDetails()
		setReviewForms({
			label: form?.form_name,
			value: form?.form_id,
			data: form,
		})
	}, [form])

	const reviewForms =
		Array.isArray(mappedForms) &&
		mappedForms
			.map(form => {
				return {
					label: form.form_name,
					value: form.form_id,
					data: form,
				}
			})
			.filter(Boolean)

	return (
		<Drawer
			className={`form-add-onfly-drawer`}
			isOpen={isOpen}
			placement='right'
			onClose={onClose}
			size='md'
		>
			<DrawerOverlay className={`form-add-onfly-drawer-overlay`} />
			<DrawerContent className={`form-add-onfly-drawer-content`}>
				<DrawerHeader fontSize='lg' fontWeight='900'>
					{locale['Compose E-Mail']}
				</DrawerHeader>
				<Divider />
				<DrawerCloseButton className={`form-add-onfly-drawer-close-button`} />
				<DrawerBody className={`form-add-onfly-drawer-body`}>
					<VStack my={5}>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Receiver email']}
							</FormLabel>
							<Input
								type='email'
								placeholder={locale['Enter email']}
								value={email}
								onChange={e => setEmail(e.target.value)}
							/>
							<Text color='gray'>
								{locale['Commas can be used to separate multiple recipients']}{' '}
							</Text>
						</FormControl>
						<FormControl>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Receiver Name']}
							</FormLabel>
							<Input
								placeholder={locale['Enter Receiver Name']}
								value={receiverName}
								onChange={e => setReceiverName(e.target.value)}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Sender Name']}
							</FormLabel>
							<Input
								placeholder={locale['Enter Sender Name']}
								value={senderName}
								onChange={e => setSenderName(e.target.value)}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Form for Entry']}
							</FormLabel>
							<Select
								id={'forms-for-review'}
								options={reviewForms}
								onChange={setReviewForms}
								placeholder={locale['Select Forms']}
								borderRadius='md'
								value={selectedReviewForms}
								size='md'
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Add Subject Line']}
							</FormLabel>
							<Input
								placeholder={locale['Add Subject Line']}
								value={subject}
								onChange={e => setSubject(e.target.value)}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel fontWeight='400' fontSize='md' my={1}>
								{locale['Message']}
							</FormLabel>
							<Textarea
								placeholder={locale['Type in a message']}
								value={message}
								onChange={e => setMessage(e.target.value)}
							/>
						</FormControl>
					</VStack>
					<Text color='red'>{error}</Text>
				</DrawerBody>
				<DrawerFooter>
					<Box mt={2} w='full'>
						<AlertBox
							isOpen={isAlertOpen}
							onClose={customClose}
							Message={alertMessage}
						/>
						<HStack justifyContent='flex-end'>
							<Button
								variant='outline'
								size='md'
								borderRadius='sm'
								colorScheme={localStorage.getItem('color')}
								bg='white.400'
								w='140px'
								ml={1}
								// onClick={onClose}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_DIALOG_BOX,
									// 	action: 'Send Email',
									// 	label: 'Reset Modal',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Compose EMail',
									// 	buttonName: 'Reset',
									// })
									resetForm()
								}}
							>
								{locale['Reset']}
							</Button>
							<Button
								variant='solid'
								size='md'
								borderRadius='sm'
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								w='140px'
								ml={1}
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_DIALOG_BOX,
									// 	action: 'Send Email',
									// 	label: `Sending Mail From ${senderName}`,
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Compose EMail',
									// 	buttonName: 'Send Email',
									// })
									onSendMail()
								}}
							>
								{locale['Send Email']}
							</Button>
						</HStack>
					</Box>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	)
}

export default OriginFormSendEmail
